<template>
  <b-carousel-item>
    <section id="lunch-launch" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute">
          <g id="lunch-launch-layer">
            <g id="LL-pink-rect" class="animate__animated animate__fadeInUp _2">

                <rect x="485.64" y="444.36" transform="matrix(-5.476911e-07 1 -1 -5.476911e-07 1159.9644 85.6729)"
                      class="black" width="103" height="356.91"/>

              <rect x="475.69" y="429.86" transform="matrix(-5.476911e-07 1 -1 -5.476911e-07 1135.5126 81.1199)"
                    class="red black-stroke small-stroke" width="103" height="356.91"/>
            </g>
            <g id="LL-yellow-rect" class="animate__animated animate__fadeInLeft _2">

                <rect x="148.53" y="332.35" transform="matrix(-5.476911e-07 1 -1 -5.476911e-07 710.8421 310.7692)"
                      class="black" width="103" height="356.91"/>
              <g>
                <rect x="12.63" y="445.8" class="yellow" width="354.91" height="101"/>
                <path class="black"
                      d="M366.54,446.8l0,99l-352.91,0l0-99H366.54 M368.54,444.8H11.63l0,103l356.91,0L368.54,444.8L368.54,444.8z"
                />
              </g>
            </g>
            <g id="circle" class="animate__animated animate__fadeInTopLeft _2">
              <circle class="black" cx="197.62" cy="279.2" r="167.02"/>
              <circle class="blue black-stroke small-stroke" cx="185.85" cy="257.01" r="167.02"/>
            </g>
            <g id="LL-pink-text" class="animate__animated animate__fadeInRight _2">
              <rect x="578.1" y="39.19" class="black" width="223.01" height="412.74"/>
              <rect x="558.7" y="21.41" class="red black-stroke small-stroke" width="223.01" height="412.74"/>
              <rect x="570.43" y="34.67" class="yellow9" width="199.54" height="434.63"/>
              <text transform="matrix(1 0 0 1 570.4325 47.8426)"><tspan x="0" y="0" class="lufga black svg-text-18">In 2014, I had the </tspan>
                <tspan x="0" y="21.6" class="lufga black svg-text-18">opportunity to attend  </tspan>
                <tspan x="0" y="43.2" class="lufga black svg-text-18 svg-text-18">			</tspan>
                <tspan x="123" y="43.2" class="lufga black svg-text-18">Summer</tspan>
                <tspan x="0" y="64.8" class="lufga black svg-text-18">Academy in San </tspan>
                <tspan x="0" y="86.4" class="lufga black svg-text-18">Francisco, learn </tspan>
                <tspan x="0" y="108" class="lufga black svg-text-18">objective-C, design </tspan>
                <tspan x="0" y="129.6" class="lufga black svg-text-18">and program a mobile </tspan>
                <tspan x="0" y="151.2" class="lufga black svg-text-18">app from scratch, and </tspan>
                <tspan x="0" y="172.8" class="lufga black svg-text-18">ship it to the AppStore.</tspan>
                <tspan x="0" y="216" class="lufga black svg-text-18">Over the course of </tspan>
                <tspan x="0" y="237.6" class="lufga black svg-text-18">writing this game, I </tspan>
                <tspan x="0" y="259.2" class="lufga black svg-text-18">started to get a feel </tspan>
                <tspan x="0" y="280.8" class="lufga black svg-text-18">for retro aesthetics and </tspan>
                <tspan x="0" y="302.4" class="lufga black svg-text-18">single screen layouts, </tspan>
                <tspan x="0" y="324" class="lufga black svg-text-18">which I’m now realizing </tspan>
                <tspan x="0" y="345.6" class="lufga black svg-text-18">I’ve developed rather a </tspan>
                <tspan x="0" y="367.2" class="lufga black svg-text-18">soft spot for.</tspan></text>
              <a xlink:href="https://makeschool.org/" target="_black" class="svg-link"><text transform="matrix(1 0 0 1 570.8109 91.0668)" class="lufga black svg-text-18">MakeSchool’s</text></a>
            </g>
            <g id="LL-white-text" class="animate__animated animate__fadeIn _3">
              <rect x="125.51" y="203.39" class="black" width="432.88" height="401.45"/>
              <g>
                <rect x="107.31" y="187.23" class="white" width="437.41" height="397.45"/>
                <path class="black"
                      d="M542.72,189.23v393.45H109.31V189.23H542.72 M546.72,185.23H105.31v401.45h441.41V185.23L546.72,185.23z"/>
              </g>
              <polygon class="yellow9"
                       points="532.17,572.86 121.88,572.86 121.88,308.48 173.42,308.48 173.42,200.38 532.17,200.38 		"/>
              <text transform="matrix(1 0 0 1 173.4178 213.5545)"><tspan x="0" y="0" class="lufga black svg-text-18">Pitch: It’s lunchtime in the cafeteria, and </tspan>
                <tspan x="0" y="21.6" class="lufga black svg-text-18">you’ve got to feed all the hungry kids. </tspan>
                <tspan x="0" y="43.2" class="lufga black svg-text-18">But none of them will come up to get </tspan>
                <tspan x="0" y="64.8" class="lufga black svg-text-18">their food, so you have to fling the sloppy </tspan>
                <tspan x="0" y="86.4" class="lufga black svg-text-18">joes at them. Hurry, before they start </tspan>
                <tspan x="-51.54" y="108"
                       class="lufga black svg-text-18">crying, and careful not to overfeed anyone, </tspan>
                <tspan x="-51.54" y="129.6"
                       class="lufga black svg-text-18">they’ll explode! Can you survive long enough to </tspan>
                <tspan x="-51.54" y="151.2" class="lufga black svg-text-18">make it to the lunch rush bonus round? </tspan>
                <tspan x="-51.54" y="194.4"
                       class="lufga black svg-text-18">My priority here was UX. Did users understand </tspan>
                <tspan x="-51.54" y="216"
                       class="lufga black svg-text-18">how to play it without a lengthy explanation? </tspan>
                <tspan x="-51.54" y="237.6"
                       class="lufga black svg-text-18">Was it different from the other games on their </tspan>
                <tspan x="-51.54" y="259.2"
                       class="lufga black svg-text-18">phone? Did they want to play it again? Ensuring </tspan>
                <tspan x="-51.54" y="280.8"
                       class="lufga black svg-text-18">a game is fun isn’t exactly a concrete goal, so </tspan>
                <tspan x="-51.54" y="302.4"
                       class="lufga black svg-text-18">the only way I could determine its level of </tspan>
                <tspan x="-51.54" y="324"
                       class="lufga black svg-text-18">fun-ness was to make everyone I knew playtest </tspan>
                <tspan x="-51.54" y="345.6"
                       class="lufga black svg-text-18">it. If you knew me that summer, I’m sorry.</tspan></text>
            </g>
            <g id="Z_1_" class="animate__animated animate__fadeIn _4 ">
              <polygon class="black" points="45.05,603.97 70.95,590.79 108.39,607.65 96.42,644.36 135.05,658.4 109.15,670.7 68.32,657.53
                81.93,618.01 		"/>
              <polygon class="blue black-stroke small-stroke" points="38.01,595.38 63.91,582.21 101.34,599.06 89.37,635.77 128.01,649.82 102.11,662.11 61.28,648.94
                74.89,609.43 		"/>
            </g>
            <g id="zigzag_1_" class="animate__animated animate__fadeIn _4 ">
              <polygon class="black" points="514.25,165.86 532.17,155.49 504.06,148.53 511.11,120.47 482.1,114.03 489.18,85.23 461.05,78.76
                468.68,51.25 450.25,62.1 443.51,89.34 472.12,96.31 465.15,124.91 494.38,130.41 486.9,159.8 		"/>
              <polygon class="blue black-stroke small-stroke" points="506.39,157.38 524.32,147 496.2,140.05 503.26,111.98 474.25,105.54 481.32,76.74 453.2,70.28
                460.83,42.77 442.4,53.62 435.66,80.85 464.27,87.82 457.3,116.43 486.52,121.93 479.04,151.32 		"/>
            </g>
            <g id="logo_1_" class="animate__animated animate__fadeIn _5">
              <rect x="593.15" y="490.59" class="black" width="141.7" height="141.7"/>
              <g>
                <defs>
                  <rect id="SVGID_9_" x="593.15" y="490.59" width="141.7" height="141.7"/>
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlink:href="#SVGID_9_" style="overflow:visible;"/>
                </clipPath>
                <path style="clip-path:url(#SVGID_2_);fill:#F2F0EC;" d="M477.76,560.12L572.18,633h-7.66l-94.42-72.88H477.76z M507.69,560.12
                  L602.11,633h7.67l-94.42-72.88H507.69z M526.49,560.12L620.91,633h7.66l-94.42-72.88H526.49z M545.29,560.12L639.71,633h7.66
                  l-94.42-72.88H545.29z M564.09,560.12L658.5,633h7.66l-94.42-72.88H564.09z M488.9,560.12L583.31,633h7.67l-94.42-72.88H488.9z
                   M582.74,560.12L677.15,633h7.66l-94.42-72.88H582.74z M620.33,560.12L714.75,633h7.67L628,560.12H620.33z M639.13,560.12
                  L733.54,633h7.66l-94.42-72.88H639.13z M657.93,560.12L752.34,633h7.66l-94.42-72.88H657.93z M676.72,560.12L771.14,633h7.66
                  l-94.42-72.88H676.72z M601.53,560.12L695.95,633h7.67l-94.42-72.88H601.53z M694.98,560.12L789.38,633h7.66l-94.4-72.88H694.98z
                   M732.55,560.12l94.4,72.88h7.66l-94.4-72.88H732.55z M751.34,560.12l94.4,72.88h7.66L759,560.12H751.34z M770.13,560.12
                  L864.53,633h7.66l-94.4-72.88H770.13z M788.91,560.12L883.32,633h7.66l-94.4-72.88H788.91z M713.77,560.12l94.4,72.88h7.66
                  l-94.4-72.88H713.77z M807.55,560.12L901.96,633h7.66l-94.4-72.88H807.55z M845.12,560.12l94.4,72.88h7.66l-94.4-72.88H845.12z
                   M863.91,560.12l94.4,72.88h7.66l-94.41-72.88H863.91z M882.7,560.12L977.1,633h7.66l-94.4-72.88H882.7z M901.49,560.12
                  L995.89,633h7.66l-94.4-72.88H901.49z M826.34,560.12l94.4,72.88h7.66L834,560.12H826.34z M506.96,487.24l94.41,72.88h7.66
                  l-94.4-72.88H506.96z M544.53,487.24l94.4,72.88h7.66l-94.4-72.88H544.53z M563.32,487.24l94.4,72.88h7.66l-94.41-72.88H563.32z
                   M582.1,487.24l94.41,72.88h7.66l-94.4-72.88H582.1z M600.89,487.24l94.41,72.88h7.66l-94.4-72.88H600.89z M525.74,487.24
                  l94.4,72.88h7.66l-94.4-72.88H525.74z M619.53,487.24l94.41,72.88h7.66l-94.4-72.88H619.53z M657.1,487.24l94.4,72.88h7.66
                  l-94.4-72.88H657.1z M675.89,487.24l94.4,72.88h7.66l-94.41-72.88H675.89z M694.68,487.24l94.41,72.88h7.66l-94.4-72.88H694.68z
                   M713.46,487.24l94.41,72.88h7.66l-94.4-72.88H713.46z M638.32,487.24l94.4,72.88h7.66l-94.4-72.88H638.32z"/>
              </g>
              <g>
                <rect x="577.85" y="476.82" class="white" width="139.7" height="139.7"/>
                <path class="black"
                      d="M716.55,477.82v137.7h-137.7v-137.7H716.55 M718.55,475.82h-141.7v141.7h141.7V475.82L718.55,475.82z"/>
              </g>

              <image style="overflow:visible;" width="398" height="362" xlink:href="../assets/lunch-launch-logo.png"
                     transform="matrix(0.2802 0 0 0.2802 592.0801 494.9485)">
              </image>
            </g>
            <g id="circles2_2_" class="animate__animated animate__fadeIn _4 ">
              <g>
                <circle class="yellow black-stroke small-stroke" cx="418.8" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="399.99" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="381.19" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="362.38" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="343.58" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="324.77" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="305.96" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="287.16" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="268.35" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="249.55" cy="126.36" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="230.74" cy="126.36" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="391.29" cy="156.65" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="372.48" cy="156.65" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="353.67" cy="156.65" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="334.87" cy="156.65" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="316.06" cy="156.65" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="297.26" cy="156.65" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="458.39" cy="186.94" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="439.58" cy="186.94" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="420.78" cy="186.94" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="401.97" cy="186.94" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="383.16" cy="186.94" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="364.36" cy="186.94" r="6.59"/>
              </g>
              <g>
                <circle class="yellow black-stroke small-stroke" cx="369.99" cy="96.07" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="351.19" cy="96.07" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="332.38" cy="96.07" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="313.58" cy="96.07" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="294.77" cy="96.07" r="6.59"/>
                <circle class="yellow black-stroke small-stroke" cx="275.96" cy="96.07" r="6.59"/>
              </g>
            </g>
            <g id="triangle_1_" class="animate__animated animate__fadeIn _4 ">
              <polygon class="black" points="326.8,675.44 278.5,577.46 228.18,635.9 		"/>
              <g>
                <polygon class="yellow" points="227.63,621.57 276.05,565.34 322.53,659.62 			"/>
                <path class="black" d="M275.82,567.14l44.66,90.58l-91.18-36.56L275.82,567.14 M276.28,563.54l-50.32,58.44l98.62,39.54
                  L276.28,563.54L276.28,563.54z"/>
              </g>
            </g>
            <image style="overflow:visible;" width="168" height="299" xlink:href="../assets/lunch-launch-mockup.png"
                   transform="matrix(0.7949 0 0 0.7949 25.878 66.1024)" class="animate__animated animate__fadeIn _5">
            </image>
            <g id="circles1_1_" class="animate__animated animate__fadeIn _4 ">
              <g>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="458.53" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="477.34" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="496.15" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="514.95" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="533.76" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="552.56" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="571.37" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="590.18" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="608.98" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="627.79" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="759.89" cy="646.59" r="6.59"/>
              </g>
              <g>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="447.8" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="466.61" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="485.41" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="504.22" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="523.03" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="541.83" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="560.64" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="579.44" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="598.25" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="617.06" r="6.59"/>
                <circle class="blue black-stroke small-stroke" cx="780.05" cy="635.86" r="6.59"/>
              </g>
            </g>
          </g>
        </svg>
        <h1 class="title is-1 has-text-grey-dark is-highlighted white-highlight is-align-self-flex-start animate__animated animate__fadeInDown _2">
          lunch launch</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "LunchLaunch"
}
</script>
<style scoped>

.yellow9 {
  clip-path: url(#SVGID_10_);
  fill: none;
}
</style>
